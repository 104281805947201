.user-title-col {
  padding: 10px 20px;
  display: flex;
  margin-top: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.user-table {
  max-width: 100%;
  min-height: 100%;
  padding: 0px 30px;
  
}

.User-table *.ant-table-thead > tr > th { 
  font-size: 18px;
  max-width: 100%;
  color: #63799b;
  font-weight: 500;
  text-align: left;
  background: #ffff;
  border-color: #f0eff4;
  border-bottom: 1px solid #ffff;
  
}
.User-table *.ant-table-thead > tr > th {

  border-bottom: 1px solid #f0eff4;
}

.user-data-table *.ant-table {
  
  font-size: 18px;
  max-width: 100%;
  overflow-x:auto;
  background:#fff;
  border-radius: 7px;
  color: @primary-color;
}

.user-button-add {
  border: none;
  height: 3rem;
  width: 120px;
  max-width: 100px ;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  max-height: auto !important;
  background-color: @error-color;
  
  

  &:hover {
    color: #fff;
    background-color: @error-color;
  }
  &:focus {
    color: #fff;
    background-color: @error-color;
  }
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  color: #2a2866;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  margin-right: 8px;
  padding: 8px 8px;
  font-size: 16px;
  background: #fff;
  border-color: #2a2866 !important;
  border-radius: 3px;
}

.user-box {
  background: #ffffff;
  border-radius: 10px;
}

.ant-pagination-item-active a {
  color: #f9f9fb;
  // background-color: #2A2866;
  border: #2C2F8F;
  &:hover {
    color: #fff !important;
    background-color: #2A2866;
  }

}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #2A2866;
  border: #2C2F8F;
  transition: none;
}
.ant-pagination-item-active a {
  color: #f9f9fb;
  border: #2C2F8F;
  background-color:#2C2F8F ;
}
