.assessmentFormMember-mainbox {
  border: 1px solid;
  background: #ffffff;
  border: 1px solid #f0eff4;
  border-radius: 10px;
}
.assessmentForm-toptextAchievement {
  padding: 25px 30px 10px 35px;
  font-size: 22px;
  letter-spacing: 0.3px;
  // color: #2A2866;
  font-weight: bold;
  opacity: 1;
}
.assessmentList-textinboxCreateAchievement {
  letter-spacing: 0.3px;
  // color: #2A2866;
  font-weight: bold;
  opacity: 1;
}
.assessmentForm-boxCreateAchievement {
  background: #fedd10;
  border-radius: 10px;
  align-self: end;
  text-align: center;
}
.assessmentForm-boxCancelAchievement {
  padding: 25px 30px 10px 35px;
  align-self: end;
  text-align: center;
  border: 1px solid;
  background: #ffffff;
  border: 1px solid #f0eff4;
  border-radius: 10px;
}

.assess-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
}

.assess-detail-box {
  margin-bottom: 1.5rem;
  border-radius: 7px;
  padding: 15px 40px;
  background: #ffffff;
  border: 1px solid #55555529;
  // box-shadow: 0px 0px 15px #00000017;
  .assess-detail-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 10px 0;
    color: @primary-color;
  }
  .assess-detail-img-position {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .assess-detail-img {
      max-width: 100%;
      height: auto;
      border: 8px solid #fff;
      box-shadow: 2px 8px 15px #00000017;
    }
    .ant-upload-picture-card-wrapper {
      width: unset;
    }
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 20px 0;
  }
  .ant-form-item-label > label {
    // color: @primary-color;
    font-size: 17px;
    font-weight: bold;
  }
  .assess-form-fill {
    border-radius: 3px;
    // color: @primary-color;
    min-width: 100%;
    // border: 1px solid #8B929D;
    box-shadow: 0px 0px 3px #00000017;
    height: 3rem;
  }
  .ant-picker-input > input {
    // color: @primary-color;
    border-radius: 0px;
    letter-spacing: 1px;
  }
}

.assess-white-backgournd {
  padding: 30px 40px 40px 40px;
  background: #ffffff;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  opacity: 1;
  > .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0 0;
  }
}
.assess-button-cencel {
  // color: @primary-color;
  font-weight: bold;
  font-size: 20px;
  border: 1px solid #8b929d;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 7px 3px 0;
  height: 50px;
  padding: 5px 60px;
  &:hover {
    border: 1px solid #8b929d;
    color: black;
  }
  &:focus {
    border: 1px solid #8b929d;
  }
}

.assess-button-build {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  border: 1px solid @error-color;
  background-color: @error-color;
  border-radius: 8px;
  height: 50px;
  padding: 5px 64px;
  &:hover {
    border: 1px solid @error-color;
    background-color: @error-color;
    color: #ffffff;
  }
  &:focus {
    border: 1px solid @error-color;
    background-color: @error-color;
    color: #ffffff;
  }
}

.assess-content-title {
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.assess-list-style .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.assess-collapse .ant-collapse {
  border: 1px solid #f0eff4;
  .ant-collapse-item {
    border-bottom: 1px solid #f0eff4;
  }
  .ant-collapse-content {
    // border-bottom: 1px solid #f0eff4;
    border-top: 1px solid #f0eff4;
  }
  .ant-collapse-item {
    border-bottom: 1px solid #f0eff4;
  }
}
.assess-list-font-style
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  color: @primary-color !important;
  font-weight: bold;
  letter-spacing: 0.7px;
}

.assess-detail-style .ant-list-split .ant-list-item {
  // border-bottom: 1px solid #F0EFF4;
  padding: 12px 5rem;
  font-size: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.assess-subhead-style
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 1rem 3.5rem !important;
  font-size: 1.2rem;
  font-weight: unset;
}

.assess-head-style
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 1rem 2rem 1rem 40px;
}
.assess-collapse-style .ant-collapse {
  // border-radius: 0px;
  background-color: #ffffff;
  border: 0px solid #f0eff4;
  .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0px 0px 0px 0px;
  }
}

.assess-img-upload-style {
  font-size: 50px;
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 50%;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 50%;
    padding: unset;
    border: 8px solid #fff;
    box-shadow: 1px 1px 15px #00000017;
  }
  .ant-upload-list-picture-card-container {
    margin: unset;
    min-width: 8rem;
    min-height: 8rem;
  }
  .ant-upload.ant-upload-select-picture-card {
    min-width: 8rem !important;
    min-height: 8rem !important;
    margin: unset;
    border-radius: 50%;
    border: 8px solid #fff;
    box-shadow: 1px 1px 15px #00000017;
    &:hover {
      border-color: #fff;
    }
  }
}
.ant-modal-title {
  color: @primary-color;
  font-weight: bold;
  font-size: 1.3rem;
}

.assess-form-style {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}
