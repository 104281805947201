.header-Title {
  // margin: 24px 16px;
  border: #fff;
  padding-left: 40px;
  color: #fff;
}

.header-font-title {
  font-size: 30px;
  font-weight: bold;
}

.header-botton {
  // height: 40px;
  width: auto;
  border: none;
  color: @primary-color;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 17px;
}

.member-select-year {
  display: flex;
  justify-content: center;
  width: 350px;
}


.header-back-icon {
  display: block;
  color: @primary-color;
  background-color: #fff;
  // padding: auto;
  padding: 6px 11px 10px 9px;
  border-radius: 5px;
  // margin-right: 10px;
  // padding-top: 5px;
}

.header-inprocess {
  border-color: #fff;
  color: #fff;
  background-color: @primary-color;
  border-radius: 6px;
  margin-right: 4px;
  font-size: 17px;
  display: flex;
  padding: 8px;
  align-items: center;

  &:hover {
    color: #fff;
  }
}

.header-dropdown-fontcolor {
  color: @primary-color;
}

.header-dropdown {
  color: @primary-color;

  &:hover {
    color: @error-color;
  }

  &:focus {
    color: @error-color;
  }
}

.header-text-center {
  display: flex;
  line-height: 1rem !important;
  align-items: center;
}

.header-text-center:hover {
  display: flex;
  line-height: 1rem !important;
  align-items: center;
  color: rgb(185, 185, 185);
}

.header-center {
  align-items: center !important;
  display: inline-grid !important;
}

.header-button-profile {
  color: #fff;
  border: none;
  padding: unset;

  .header-profile {
    display: flex;
    line-height: 14px !important;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 15px;
  }

}

.ant-cascader-menu {
  height: auto !important;
  color: @primary-color;
}

.header-select-dropdown {
  color: @primary-color;
  font-weight: bold;
  // border: 0px solid #ffffff;
  background-color: transparent;
  margin-right: 5px;
  font-size: 17px;

  >.ant-input {
    background-color: #ffffff !important;
    border-radius: 5px;
  }

  >.ant-cascader-picker-label {
    line-height: 1rem;
    display: flex;
    align-items: center;
  }
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  font-weight: 600;
  background-color: #9e9ca623;
  color: @error-color;
}


.header-dropdown-style {
  .ant-dropdown-menu {
    // margin: 4px 0 0 0;
    border-radius: 5px;
    // border: 8px solid #fff;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    color: @primary-color;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    border-radius: 5px;
  }
}

.hn-header {
  display: flex;
  // align-items: flex-start;
  margin: 0px 40px;

  // justify-content: space-between;
  .ant-btn>.anticon {
    line-height: 0px;
  }
}

.hn-title-text {
  display: flex;
  line-height: 1rem;
  flex-direction: column;
  margin-left: 1rem;
  color: #fff;

  .header-font-subtitle {
    font-size: 20px;
    margin-top: 10px;
  }
}

.hn-head-title {
  display: inline-flex;
  align-items: center;
  // flex-wrap: wrap;
}

.hn-avarta {
  border: 2px solid #fff;
  margin: 0px 0.5em 0px 0px;
  // border-color: #fff;
}

.hn-select-style-inChart {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  width: 400px;
}

.hn-select-style {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  width: 520px;

  >.ant-select {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    margin-right: 5px;
    width: 120px;

    >.ant-select-arrow {
      margin-top: -8px;
    }

  }

  .hn-select-btn-spacing {
    display: flex;
    justify-content: space-around;
    margin-left: 5px;
    margin-right: 5px;
  }

  >.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 17px;
  }

  >.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  // >.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  //   margin-left: 2px;
  // }
  >.ant-cascader-picker {
    background-color: transparent;
    align-self: center;
    // margin-bottom: 5px;
    width: auto;
  }

  >.ant-btn {
    cursor: unset;
    border-radius: 5px;

    .ant-btn:not([disabled]):active {
      outline: unset;
      box-shadow: unset;
    }
  }
}


.header-profile-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.hn-hamburger {
  display: flex;
  align-items: center;
  background-color: transparent;
  display: none;
  border: 0px;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #fff;
  }

  &:focus {
    background-color: transparent;
    color: #fff;
    outline: none;
  }
}

.header-dropdown-btn {
  border-bottom: 1px solid #455B6314;

  .header-dropdown-item {
    border-left: 1px solid #455B6314 !important;
    margin-right: -0.15px;
    font-weight: bold;
    font-size: 20px;
    color: @primary-color;
  }
}

.header-profileHam {
  color: #fff;
  border: none;

  .header-profile-name {
    color: #fff;
    display: flex;
    line-height: 14px !important;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .header-profile-role {
    color: #fff;
    display: flex;
    line-height: 14px !important;
    align-items: center;
    font-size: 1rem;
  }

  .hn-avarta {
    border: 2px solid;
    margin: 0px 0.5em 0px 0px;
    // border-color: #fff;
  }
}

.hn-drawer-header {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header {
    position: relative;
    padding: 16px 16px;
    color: #f9f9fb;
    background: #2a2866;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0 0 0 0;
  }

  .ant-card-grid {
    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    color: @text-color-dark;
    display: flex;
    align-items: center;
  }
}

.hn-icon-svg-white {
  filter: brightness(0) invert(1)
}


@media screen and (max-width: 992px) {
  .header-profile-btn {
    display: none;
  }

  .header-profile-btn.show {
    display: flex;
  }

  .hn-hamburger {
    display: contents;
  }

  .hn-header {
    margin: 0 8px;
    align-items: flex-start;
  }

  .hn-title-text {
    margin-right: unset;
    margin-bottom: 10px;
    align-items: center;
  }

  .hn-title-center {
    justify-content: center;
    position: relative;
    // justify-content: space-around;
  }

  .hn-select-btn-spacing {
    margin-left: 1rem;
  }

  .hn-select-style {
    // flex-wrap: unset;
    justify-content: center;
    display: inline-flex;
    align-items: flex-start;
    align-content: flex-start;
  }
}

@media screen and (max-width: 340px) {
  .hn-select-btn-spacing {
    margin-left: unset;
  }
}

@media screen and (max-width: 1200px) {
  .background>.ant-layout-header {
    margin-bottom: 1rem;
  }
}