
  .button{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    padding: 10px 30px 10px 0px;
  }
  
.form-button-cancle{
  width: 150px;
  height: 3rem;
  color:#2a2866;
  cursor: pointer;
  border-radius: 5px;
  border-color: #00000029 !important;
  background-color: #F0EFF4 !important;
  
  }

.form-button-save{
  width: 150px;
  height: 3rem;
  border: none;  
  cursor: pointer;
  color :#ffffff;
  border-radius: 5px;
  border-color: none ; 
  background-color:#1FA361;

  &:hover{
    color: #fff ;
    background-color:#1FA361 ;
  }
  &:focus{
    color: #fff ;
    background-color:#1FA361;
  }
}



