.DashMember-col-background {
  padding: 20px 20px 20px 20px;
  margin-bottom: 8px;
  background: #FFFFFF;
  border: 1px solid #F0EFF4;
  border-radius: 10px;
  opacity: 1;
}

.DashMember-percent-assess-background {
  margin-bottom: 8px;
  background: #ffffff;
  border: 1px solid #F0EFF4;
  border-radius: 10px;
  opacity: 1;
}

.DashMember-box-title {
  line-height: 1.5rem;

  .DashMember-text-title {
    font-size: 25px;
    font-weight: bold !important;

    .skeleton-id {
      width: 200px;
      height: 20px;
      border-radius: 10px;
    }
  }

  .DashMembe-role {
    font-size: 20px;

    .skeleton-status {
      width: 100px;
      height: 15px;
      border-radius: 10px;
    }
  }
}

.DashMember-percent-text {
  // line-height: 2rem;  
  display: flex;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  color: @error-color;
}

.DashMember-img-content {
  img {
    float: right;
    max-width: 90%;
    height: auto !important;
  }
}

.DashMember-percent-assess-block {
  margin: 20px 15px 20px 40px;

}

.DashMember-text-center {
  text-align: center;
}

.DashMember-chart-title {
  display: flex;
  font-size: 25px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 20px;
}

.DashMember-chart-select {
  color: #fff;
  font-weight: bold;
  width: 120;

  .ant-select-selector {
    background-color: @primary-color  !important;
    border: none;
  }
}

.DashMember-avatar {
  max-width: 100%;
  border: 2px solid #F0F0F5;
}

.DashMember-user-title {
  margin: 15px 0px 10px 0px;
  font-size: 20px;
  font-weight: bold;
}

.DashMember-col-processnow {
  border-bottom: 1px solid #F0F0F5;
  padding: 10px 0px 10px 0px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5rem;

  .DashMember-now-status {
    color: @error-color;
  }

  .DashMember-col-processnow-icon {
    background-color: @warning-color;
    margin-right: 10px;
  }

}

.DashMember-col-background-processnow {
  padding: 10px 0px 0px 0px;
  background: #FFFFFF;
  border: 1px solid #F0EFF4;
  border-radius: 10px;
  opacity: 1;

  .DashMember-col-processnow-date {
    padding: 10px 20px 20px 20px;
  }
}

.DashMember-user-detail {
  padding: 3px;

  .DashMember-user-detail-title {
    font-weight: bold;
    display: inline-flex;
    justify-content: flex-end;
  }
}

.DashMember-icon-block {
  position: relative;

  .DashMember-icon-img {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.box-image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 70px;
  margin-top: 15px;

  .imageCompaire {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #70707030;
    box-shadow: 0px 2px 5px #8A8A8A47;

  }
}


.data-chart-compare {
  display: flex;
  flex-direction: row;
  width: 100%;

  .box-of-select {
    display: flex;
    justify-content: center;

    .tab-variable-and-select {
      width: 100%;

      .tab-variable {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;

        .tab-variableOne-color {
          background-color: #EB2227;
          width: 50px;
          height: 10px;
          margin-right: 10px;
        }

        .tab-variableTwo-color {
          background-color: #2A2866;
          width: 50px;
          height: 10px;
          margin-right: 10px;
        }
      }

      .tab-select {
        width: 100%;

      }
    }
  }
}