
  .form-box {
  padding:  10px 10px;
  width: 100%;
  min-height: 100%;
  border-radius: 10px;
  background-color: white;

  }

  .title-text{
    
    // font-size: 2rem;
    font-weight: bold;
    // color: #2C2F8F;
    padding: 10px 0px 10px 30px;
  
  }
  .title {
    // font-size: 2rem;
    font-weight: bold;
    padding: 8px 20px;
    // color: #2c2f8f !important;
    margin-bottom: 0px !important ;
   
  }