@import "~antd/lib/style/themes/default.less";
@primary-color: #2a2866;
@success-color: #1fa361;
@info-color: #4887f2;
@warning-color: #fedd10;
@error-color: #eb2227;
@danger-color: #eb2227;
@dark-color: #353535;
@dark-gray-color: #777777;
@gray-color: #8b929d;
@layout-header-background: #2a2866;
@layout-header-height: 92px;
@border-radius-base: 7px;
@border-radius-sm: 5px;
@text-color-dark: #8b929d;

@font-size-base: 15px; // major text font size
@font-family: "FCIconic";

@font-face {
  font-family: "FCIconic";
  src: url(../fonts/FCIconicRegular.ttf) format("truetype");
}
