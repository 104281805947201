.content-stylee {
  // margin: 0px 16px;
  // padding: 0px 24px;
  // position: relative;
  height: 87vh;
  // overflow: hidden !important;
  overflow: auto;
}

.hn-header-new {
  // margin: 0 40px;
  // min-height: 92px !important;
  margin: 30px 40px 30px 40px;
  display: grid;
  align-items: center;
}

.content-form-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
  background-color: white;
  border-radius: 10px;
  height: 45px;
  font-weight: bold;
}