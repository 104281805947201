.content-step {
  * .ant-steps-item-container {
    .ant-steps-icon {
      .step-item {
        background-color: @success-color;
        border-radius: 50%;
        width: 48;
        height: 48;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #f0eff4;
      }
    }
  }
}
