@import "./variables.less";

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  /*background: red;*/
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: @primary-color;
  /*outline: 1px solid slategrey;*/
}

.h-100vh {
  height: 100vh;
}
.max-h-100vh {
  max-height: 100vh;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}
.flex-column-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text-primary {
  color: @primary-color;
}
.text-success {
  color: @success-color;
}
.text-black {
  color: #000;
}
.text-black {
  color: @text-color-dark;
}

.text-title {
  font-weight: bold;
  font-size: 1.85rem;
  color: @primary-color;
}
.text-title-black {
  font-weight: bold;
  font-size: 1.85rem;
  color: #000;
}
.text-title-white {
  color: #fff;
  font-weight: bold;
  font-size: 1.85rem;
  letter-spacing: 1px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  flex-direction: column;
}
.shadow {
  -webkit-box-shadow: 0px 5px 22px -9px rgba(34, 60, 80, 0.2) !important;
  -moz-box-shadow: 0px 5px 22px -9px rgba(34, 60, 80, 0.2) !important;
  box-shadow: 0px 5px 22px -9px rgba(34, 60, 80, 0.2) !important;
}
.button-danger {
  color: #fff;
  background-color: @danger-color;
}
.button-danger:hover {
  color: #fff;
  outline: none;
  border-color: #ca1f22;
  background-color: @danger-color !important;
}
.button-danger:active,
.button-danger:focus {
  color: #fff;
  border-color: #ca1f22;
  background-color: @danger-color !important;
}

.button-success {
  background-color: @success-color; /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
.button-success:hover {
  background-color: #059862 !important;
}
.button-success:active {
  background-color: @success-color !important;
}

.icon-button-default {
  color: #d9d9d9;
}
.icon-button-default:hover {
  * {
    color: rgb(128, 128, 128) !important;
  }
}

.text-point{
    background: #c54047;
    // background: #6d7694;
    color: white;
    padding: 5px 15px;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    right: -35px;
    box-shadow: 0px 3px 3px #ccc;
}
.ant-modal-mask {
  background-color: rgba(46, 46, 46, 0.137)!important;
}
.ant-modal-content{
  box-shadow:0 3px 9px 1px rgba(80, 80, 80, 0.05), 0 6px 5px 0px rgba(80, 80, 80, 0.05), 0 9px 5px 1px rgba(80, 80, 80, 0.05) !important;
}