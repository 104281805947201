.main-dashboard .card-list .card-item {
  width: 100%;
}
.main-dashboard .card-list .card-item > div {
  height: 100%;
  max-height: auto;
  padding:  1rem 1rem 1rem 0;
  border-radius: 6px;
  border-color: #F0EFF4;
  background-color: #F9F9FB;
}

.main-dashboard .card-list .card-text-display {
  display: flex;
  align-items: center;
}

.dash-line-height {
  align-items: center;
  line-height: 1rem;
}

.dash-detail-title {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.dash-detail-block {
    position: relative;
    .dash-detial-background-img {
        position: absolute;
        max-height: 100%;
        width: auto;
    }
}

.dash-process-number {
  font-weight: bold;
  font-size: 2rem;
}

.dash-list-center {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.dash-list-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.dash-icon-size {
  padding: 1rem;
  max-width: 100%;
}