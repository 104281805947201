@import "../../assets/less/themeColor.less";

.login-background-img {
  background-image: url("../../assets/images/loginBG1.png");
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-white-box {
  width: 400px;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;  
  background: #ffffff ;
  box-shadow: 0px 3px 30px #2a28661a;
  border: 1px solid #f0eff4;
  border-radius: 15px;
  opacity: 1;
}

.login-inblock-center {
  width: 80%;
  height: 80%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.login-title {
  font-weight: bold;
  font-size: 30px;
}

.login-button {
  border-radius: 3px;
  font-weight: bold;
}

.login-inputbox {
  border-radius: 3px !important;
  opacity: 1 !important;
  width: 100% !important;
}

.login-forgetpasseord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  margin: 0 0 10px 0;
}

.login-form-style {
  .ant-form-item-label > label {
  font-size: 20px;
  font-weight: bold;
  color: @primary-color;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-input{
    color: @primary-color;
    border-radius: 0;
  }
}

.login-formAnt-paddind >.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
  padding: 0 0 0 0;
}
