.box-row {
  width: auto;
  height: auto;
  border-radius: 10px;
  background: #ffffff;
}

.document-titie {
  display: flex;
  color: #2a2866;
  line-height: 2rem;
  padding: 20px 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.line {
  border-bottom: 1px solid #d5d7da;
}

.linboxcontent {
  height: auto;
  border-radius: 10px;
  border: 1px solid #00000029;
}

.boxsmalltopcontent {
  background: #fedd10;
  border-radius: 6px;
  margin: 10px 10px -18px 20px;
  height: auto;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  padding: 3px 0;
  width: max-content;
  max-inline-size: fit-content;
}

.document-datastap {
  padding: 40px 20px;
  font-size: 20px;
  color: #2a2866;
  display: flex;
  flex-direction: column;
}
.document-datastap-note {
  padding: 0px 20px 12px;
  font-size: 20px;
  color: #2a2866;
  display: flex;
  flex-direction: column;
}

.document-font {
  font-size: 20px;
  color: #2a2866;
  padding: 0px 20px;
}

.document-text {
  font-size: 20px;
  color: #2a2866;
}
.document-datastap-notetwo {
  color: #2a2866 !important;
  font-size: 20px !important;
}
.document-datastap-notetwo-evaluating {
  max-width: 100%;
  color: #fedd10 !important ;
  font-size: 20px !important;
  padding: 20px 0px !important;
}
.succeed *.ant-radio-inner {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #1fa361;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
}
.evaluating *.ant-radio-inner {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #fedd10;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
}

.document-img {
  display: inline-grid;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto !important;
  }
}

.document-img-border {
  border: 1px solid #d5d7da;
}

.circlc-yellow {
  height: 15px;
  width: 15px;
  border-color: #fff;
  background-color: @warning-color !important;
  border-style: double;
  border-radius: 50%;
  display: inline-block;
}

.document-format {
  padding: 0px 0px 0px 25px;
  display: inline-flex;
  align-items: center;
}

.document-step-style {
  margin: 2rem !important;
  padding: 0 0 30px;
}

.document-distance {
  padding: 0px 30px;
}

.see-document {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 50px;
}
