// .main-dashboard {
//   padding: 2rem;
//   min-height: 100%;
//   border-radius: 10px;
//   background-color: white;
// }

.dashAd-process-icon {
  .done {
    color: @success-color;
    font-size: 2.05rem;
  }

  .green {
    border: 1px solid @success-color;
    background-color: #FFFFFF;
  }

  .yellow {
    color: #FEDD10;
    border: 1px solid #FEDD10;
    background-color: #FFFFFF;
  }

  .blue {
    border: 1px solid #4887F2;
    background-color: #FFFFFF;
  }

  .gray {
    color: #D5D7DA;
    border: 1px solid #D5D7DA;
    background-color: #FFFFFF;
  }

  .color {
    filter: grayscale(100%) opacity(35%);
  }
}

.dashAd-title-margin {
  margin-bottom: 1rem;
}

.dashAd-icon-margin {
  margin: 0.3em 1.5em 0.5em 0.5em;
}

.main-dashboard {
  padding: 0.8rem 2rem;
  min-height: 100%;
  border-radius: 10px;
  background-color: white;

}

//---------new-------//

.dashmin-checkbox {
  padding: 0.5rem;
  font-weight: bold;
  // margin: 0.8em 0 1em 0;
  border-radius: 10px;
  border: 1px solid #70707030;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }

  .ant-checkbox-wrapper {
    margin: 1rem;
    font-size: 18px;
    color: @primary-color;

    .ant-checkbox {
      margin-right: 8px;
    }
  }
}

.dashmin-sport-title {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 1rem 0;

  .dashmin-title {
    font-weight: 500;
    font-size: 1.6rem;
  }

  .dashmin-gray-text {
    font-weight: 500;
    color: #8B929D;
    font-size: 1.3rem;
    margin: 0.5% 0 0 2%;
    align-self: center;
  }
}

.dashmin-cardlist {
  border-radius: 10px;
  border: 1px solid #70707030;
  box-shadow: 0px 2px 5px #8A8A8A47;

  .dashmin-cardlist-header-bg {
    background-color: #F9F9FB;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #70707030;

    .dashmin-cardlist-text {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 20px 8px 10px;
      align-items: center;
      justify-content: space-between;

      .dashmin-cardlist-title {
        font-weight: bold;
        font-size: 1.3rem;
      }

      .dashmin-cardlist-moreDetail {
        font-size: 1rem;
        background-color: transparent;
        border-style: none;
        font-weight: 550;
      }

      .dashmin-cardlist-moreDetail:hover {
        font-size: 1rem;
        font-weight: 550;
        background-color: transparent;
        border-style: none;
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }

  .dashmin-cardlist-detail {
    padding: 8px 20px;

    .dashmin-role-name {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.dashmin-cardlist-nodata {
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    width: 80%;
    height: 550px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.dashmin-img-style {
  // padding: 1rem;
  max-width: 25%;
  height: auto;
  margin-right: 10px;
}

.item-name-role {
  width: 50%;
}

.item-count {
  display: flex;
  justify-content: center;
  width: 50%;
  border-style: solid;
  border-radius: 5px;
  border-width: 0.5px;

}

.box-data-of-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 110px;
  margin-top: 10px;

}

.colored-circle {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0);
  height: 15px;
  width: 15px;
}
.sport-hover-modal:hover{
  background-color: #e4e4e4 !important;
  cursor: pointer;
  
}

.col-content-sport {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  width: 90%;

  .card-content-sport {
    width: 95%;
    border: 1px solid #70707030;
    border-radius: 10px;
    box-shadow: 0px 2px 5px #8A8A8A47;
    margin-bottom: 10px;

    .card-content-sport-titlt {
      width: 100%;
      // height: 50px;
      font-weight: bold;
      // display: flex;
      // justify-content: center;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .card-content-sport-name {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .card-content-sport-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 55px;
      background-color: #F9F9FB;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.dashmin-role-name {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
}

.box-IconOf-admin {
  display: flex;
  justify-content: space-around;
  align-items: center;

}