.main-ListAssessment {
    width: 100%;
}

.boxleft-ListAssessment {
    padding-top: 1rem; // min-height: 100%;
    border-radius: 10px;
    background-color: white;
}

.boxright-ListAssessment {
    padding: 1rem 3rem 3rem 3rem;
    // min-height: 100%;
    border-radius: 10px;
    background-color: white;

    .text-black-assessmentListAdmin-form {

        width: 100%;

        .evaluation-infoinmation {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .titleAseessmentListAdmin {
                margin-top: 10px;

                .assessmentListMember-list-imgBorder {
                    display: inline-flex;
                    border-radius: 50%;
                    border: 3px solid #f0f0f5;
                }

                .assessmentListMember-list-programDetail {
                    margin-left: 10px;
                }

                .assessmentListMember-list-programName {
                    font-weight: bold;
                    font-size: 18px;
                    letter-spacing: 0.3px;
                    color: #2a2866;
                    opacity: 1;
                }

                .assessmentListAdmin-list-year {
                    color: red;
                }

                .assessmentListAdmin-list-date {
                    display: flex;
                    font-size: 17px;
                    font-weight: normal;

                    .list-date-title {
                        display: flex;
                    }

                    .list-date-detail {
                        margin-right: 10px;
                    }
                }
            }

        }
    }
}