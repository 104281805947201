.upload-img {
  filter: grayscale(100%) opacity(20%);
  margin-right: 5px;
  color: #f0eff4;
}

.boxleft-FormAssessment {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.boxright-FormAssessment {
  // height: 100%;
  border-radius: 10px;
  background-color: white;

  .ant-steps {
    padding: 2rem 10rem;
  }

  * .ant-steps-item {
    line-height: 16px;
  }

  * .ant-steps-item-finish .ant-steps-item-icon {
    background-color: @success-color;
    border-color: @success-color;
    line-height: 48px;

    >.ant-steps-icon {
      color: white;
    }
  }

  * .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #ffffff !important;
    border: 2px solid @success-color;

    .ant-steps-icon {
      color: @success-color;
    }
  }

  .ant-steps-item-title {
    padding-right: 0px;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: #f0eff4 !important;
    border: 2px solid #f0eff4;

    .ant-steps-icon {
      color: #d5d7da;
    }
  }

  .ant-steps-item-icon {
    width: 48px;
    height: 48px;
    font-size: 32px;
    line-height: 42px;
    margin: 0px;

    .ant-steps-icon {
      span {
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding: 0px;
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: @success-color;
    border: 0.5px solid @success-color;
    left: 0px;
  }

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #f0eff4;
    border: 0.5px solid #f0eff4;
    left: 0px;
  }

  .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #f0eff4;
    border: 0.5px solid #f0eff4;
  }
}

.assessmentForm-texindicators {
  text-align: left;
  font-weight: bold;
  padding: 10px 0px 10px 15px;
  color: @primary-color;
  letter-spacing: 0.13px;
}

.assessmentForm-texboxleft {
  text-align: left;
  font-weight: bold;
  padding: 10px 0px 0px 15px;
  color: @primary-color;
  letter-spacing: 0.13px;
  opacity: 1;
}

.cz {
  display: flex;
  align-items: center;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 45px;
    padding-top: 6.5px;
    font-weight: normal;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: normal;
  }
}

.score {
  display: flex;
  align-items: center;
  color: @primary-color;
  letter-spacing: 0.13px;
  font-weight: bold;
  font-size: 15px;
}

.todo {
  border: 1px solid #dddddd;
  border-radius: 4px;
  position: relative;
  // height: 66px;
  padding: 10px 10px 10px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  margin-bottom: 10px;

  .ant-btn {
    padding: 0px;
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }
}

.xv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add {
  background: #dddddd;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: black;

  &:hover {
    background: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: black;
  }

  &:focus {
    background: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: black;
  }
}