.background {
  background-image: url("/assets/images/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  >.ant-layout-header {
    // min-height: 92px !important;
    height: auto;
    margin: 2rem 0 1rem 0;
  }
}
.content-style {
  margin: 0px 16px;
  padding: 24px;
  min-height: 280;
  overflow: auto;
}

@media screen and (max-width: 992px){
  .content-style {
    margin: 0px 8px;
    padding: 0px;
    min-height: 280;
    overflow: auto;
  }
}



