.assessmentListMember-title-list {
  font-size: 20px;
  display: flex;
  align-items: center;

  .assessmentListMember-list-imgBorder {
    display: inline-flex;
    border-radius: 50%;
    border: 3px solid #f0f0f5;
  }

  .assessmentListMember-list-programDetail {
    margin-left: 10px;
  }

  .assessmentListMember-list-programName {
    margin-right: 10px;
    font-weight: bold;
  }

  .assessmentListMember-list-red {
    color: @error-color;
  }

  .assessmentListMember-list-date {
    display: flex;
    font-size: 17px;
    font-weight: normal;

    .list-date-title {
      display: flex;
    }

    .list-date-detail {
      margin-right: 10px;
    }
  }
}

.assessmentListMember-mainbox {
  border: 1px solid;
  background: #ffffff;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  height: 100%;
}

.assessmentListMember-boxcontent *.ant-card-body {
  padding: 15px 0px 5px 5px;
  border-bottom: #fff;

  &:hover {
    color: @layout-header-background;
    background-color: #f9f9fb;
  }
}

.assessmentListMember-boxsuccess {
  // width: 190px;
  background: #ffffff;
  border: 1px solid #ffffff;
}

.assessmentListMember-boxcrad {
  padding: 15px 40px 40px 40px;
  border: 1px solid;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
}

.assessmentList-boxCreateAchievement {
  background: @error-color;
  box-shadow: 0px 3px 6px #2a286629;
  border-radius: 10px;
  margin-right: 8px;
  height: 50px;
  padding: 8px 20px;

  &:hover {
    background: @error-color;
    border-color: unset;
    // box-shadow: 0px 3px 6px @warning-color;
    border-radius: 10px;
  }

  &:focus {
    // background: @error-color ;
    // box-shadow: 0px 3px 6px @warning-color;
    border-radius: 10px;
  }
}

.assessmentMember-textAchievement {
  padding: 30px 30px 10px 40px;
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  letter-spacing: 0.3px;
  color: #2a2866;
  font-weight: bold;
  opacity: 1;
}

// .assessmentListMember-textsport{
//   // display: flex;
//   text-align: center;
//   text-justify: auto;
//   align-items: center;
//   font-size: 16px;
//   font-weight: bold;
//   // padding-top: 10px;
//   color: @primary-color;
// }
.assessmentListMember-textsuccessfully {
  letter-spacing: 0.1px;
  color: @primary-color;
  font-size: 18px;
  opacity: 1;
}

.assessmentListMember-boxsuccess {
  position: relative;
  background: #f9f9fb;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
}

.assessmentListMember-status {
  display: flex;
  align-items: center;
  // width: 170px;
  flex-wrap: nowrap;

  .assessmentListMember-iconStatus {
    float: left;
    justify-content: center;
    margin-right: 3px;

    .iconStatus-done {
      color: @success-color;
      font-size: 30px;
    }

    .iconStatus-inProcess {
      background-color: @warning-color;
    }
  }

  .assessmentListMember-textStatus {
    display: inline-flex;
    // .textStatus-build {
    //   color: #8B929D !important;
    // }
    // .textStatus-inProcess {
    //   color: @primary-color !important;
    // }
  }
}

.assessmentListMember-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px 0px 5px;
}

@media screen and (max-width: 576px) {
  .assessmentListMember-title-list {
    display: flex;
    align-items: center;
    flex-direction: column;

    .assessmentListMember-list-programName {
      text-align: center;
    }
  }
}

.title-FormAssessmentList {
  font-size: 3rem !important;
  color: @gray-color;
}