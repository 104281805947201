.assessmentList-boxmenupic {
  width: 100%;
  height: 100px;
  font-size: 20px;
  font-weight: bold;
  background: #ffffff;
  border-top: 1px solid #f0eff4;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  opacity: 1;

  &:hover {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 1px solid #f0eff4;
    font-weight: bold;
    height: 100px;
    width: 100%;
    background-color: #f9f9fb;
  }

  &:focus {
    color: @layout-header-background;
    border-left: 5px solid @warning-color;
    border-top: 1px solid #f0eff4;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    font-weight: bold;
    height: 100px;
    width: 100%;
    background-color: #f9f9fb;
  }

  .assessmentList-textrole {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.assessmentList-boxmenupic.active {
  color: @layout-header-background;
  border-left: 5px solid @warning-color;
  border-top: 1px solid #f0eff4;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  font-weight: bold;
  height: 100px;
  width: 100%;
  background-color: #f9f9fb;
}

.assessmentList-CaretDown {
  border-radius: 5px;
  border: 1px solid #f0eff4;
  text-align: left;

  &:hover {
    border: 1px solid #f0eff4;
    border-radius: 5px;
  }

  .assessmentList-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.assessmentList-DownStyle {
  margin: 0 10px 15px 10px;
}