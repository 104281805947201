.assessmentList-crad>.ant-card-body {
    border-bottom: #fff;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.assessmentList-boxsuccess {
    height: 55px;
    width: 190px;
    background: #F9F9FB;
    padding: 0px 0px 0px 10px;
    border: 1px solid #F0EFF4;
    border-radius: 10px;
    align-items: center;
    display: flex;
    border-style: solid;
}

.assessmentList-boxsuccess:hover {
    height: 55px;
    width: 190px;
    background: #F9F9FB;
    padding: 0px 0px 0px 10px;
    border: 1px solid #F0EFF4;
    border-radius: 10px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.assessmentList-contentincard {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}

.assessmentList-textsport {
    text-align: center;
    margin-left: 10px;
    padding-left: 8px;
    font-size: 18px;
    text-indent: 1rem;
    font-weight: bold;
    color: @primary-color;
}

.assessmentList-textstatus {
    align-items: center;
    font-size: 18px;
    letter-spacing: 0.1px;
    color: @primary-color;
}

.assessmentList-iconstatus {
    margin-right: 5px;
    font-size: 30px;
    color: @success-color  !important;
}

.hader-box-List-admin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
}

.text-title-sport {
    font-size: 24px;
    font-weight: bold;
}

.center-column-admin {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 350px;
    margin-top: 100px;
}