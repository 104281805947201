.FromUser-title-text{
    // font-size: 2rem;
    font-weight: bold;
    // color: #2C2F8F;
    padding: 0 40px;
  }
  .form-font {
    color: #001A70 !important;
    font-size: 18px;
    }
  
  
  .form-col-left{
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // .ant-upload-picture-card-wrapper {
      //   width: unset;                                               ;
      // }
    }
    
  .form-password  *.ant-input { 
    width: 100%;
    color: #2A2866;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    font-size: 18px;
    height: 3rem;
    padding: 0 40px;
  
  }
  
  .password *.ant-form-item-control-input-content {
    flex: auto;
    max-width: 80%;
    padding: 0 40px;
    
  }
  
  .form-input *.ant-input {
      color: #2A2866;
      height: 3rem;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      font-size: 18px;
      max-width: 100%;
  }
  .form-img-profile{
    cursor:pointer; 
    box-shadow: 0 4px 8px 0;
    max-width: 100%;
    height: auto !important;
   }
  
  .select *.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 3rem;
    padding: 5.5px 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
  }
  
  .form-address *.ant-input {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    height: 4.5rem !important;
    color: #2A2866;  
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
   
    }
  
  .form-item-password{
    color: #63799B !important;
    font-size: 18px;
    padding: 0px 40px;
    }
  
  .form-button-icon-arrow *.ant-select-arrow { 
    color: #63799B;  
  }
  
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 19px;
    font-size: 18px;
  }

  .ant-select {
    color: #2A2866;
}
 
// .ant-form-item-explain.ant-form-item-explain-error {
//   color: #eb2227;
//   padding: 0 40px;
// }

// .tt{
//   size: 230px !important;
//   .ant-upload-list-picture-card .ant-upload-list-item-info {
//     border-radius: 50%;
//   }
//   .ant-upload-list-picture-card .ant-upload-list-item {
//     border-radius: 50%;
//     padding: unset;
//   }
 
//   .ant-upload-list-picture-card-container {
//     margin: unset;
//     width: 12rem;
//     height: 12rem;
//   }
//   .ant-upload.ant-upload-select-picture-card {
//     margin: unset;
//     width: 12rem !important;
//     height: 12rem !important;
//     border-radius: 50%;
//     border: 5px solid #ffffff;
//     box-shadow: 1px 1px 15px #5c1a1a17;
//   }
// }