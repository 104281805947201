.indicator-topbox {
  border-bottom: 1px solid #f0eff4;
  padding-bottom: 15px;
  .ant-progress-inner {
    width: 100%;
  }
}
.indicator-Steps {
  border-bottom: 1px solid #f0eff4;
  .ant-progress-inner {
    width: 100%;
  }
}
.indicator-date {
  border-bottom: 1px solid #f0eff4;
  padding: 5px;
  display: flex;
  align-items: center;
}
.indicator-textdate {
  text-align: left;
  padding: 10px 0px 0px 15px;
  letter-spacing: 0.2px;
}
.indicator-textboxleft {
  text-align: left;
  font-weight: bold;
  padding: 10px 0px 0px 15px;
  color: @primary-color;
  letter-spacing: 0.13px;
  font-size: 20px;
}
.indicator-textindicators {
  text-align: left;
  font-weight: bold;
  padding: 10px 0px 10px 15px;
  color: @primary-color;
  letter-spacing: 0.13px;
}
.indicator-CollapseActive > .ant-collapse {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #ffffff;
  border-collapse: collapse;
  border: 0px;
  > .ant-collapse-item {
    border-top: 1px solid #d9d9d9;
    border-bottom: 0px;
  }
  > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 0px 0px;
  }
  .ant-card {
    border-radius: 0px;
  }
  *.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  // > .ant-collapse-item > .ant-collapse-header:focus {
  //   border-left: 5px solid @warning-color;
  //   border-bottom-left-radius: 0px;
  // }
  // .ant-collapse-item > .ant-collapse-header {
  //   border-left: 5px solid @warning-color;
  //   border-bottom-left-radius: 0px;
  // }
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0px;
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 10px 60px 10px 15px;
    color: @primary-color;
  }
  .ant-collapse-item.success > .ant-collapse-header {
    border-left: 5px solid @success-color;
    border-bottom-left-radius: 0px;
  }
  .ant-collapse-item.success > .ant-collapse-header:focus {
    border-left: 5px solid @success-color;
    border-bottom-left-radius: 0px;
  }
  .ant-collapse-item.draft > .ant-collapse-header {
    border-left: 5px solid @dark-gray-color;
    border-bottom-left-radius: 0px;
  }
  .ant-collapse-item.draft > .ant-collapse-header:focus {
    border-left: 5px solid @dark-gray-color;
    border-bottom-left-radius: 0px;
  }
  .ant-collapse-item.document > .ant-collapse-header {
    border-left: 5px solid @warning-color;
    border-bottom-left-radius: 0px;
  }
  .ant-collapse-item.document > .ant-collapse-header:focus {
    border-left: 5px solid @warning-color;
    border-bottom-left-radius: 0px;
  }
}
.indicator-text {
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.1px;
  .ant-btn {
    white-space: normal;
    text-align: left;
    > span {
      display: inline-flex;
    }
  }
}
.indicator-textsand {
  padding: 8px 20px;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: @text-color-dark;
  font-weight: bold;
}
.indicator-textsubmit {
  padding: 8px 20px;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: white;
  font-weight: bold;
}
.indicator-sand {
  background: #f9f9fb;
  border-color: #f9f9fb;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 21px 10px 21px 10px;

  &:hover {
    background: #f9f9fb;
    color: @text-color-dark;
    border-color: #f9f9fb;
  }
  &:focus {
    background: #f9f9fb;
    color: @text-color-dark;
    border-color: #f9f9fb;
  }
}
.submit-primary {
  background: @primary-color !important;
  border-color: @primary-color !important;
}
.submit-done {
  background: @success-color;
  border-color: @success-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 21px 10px 21px 10px;
  &:hover {
    background: @success-color;
    border-color: @success-color;
    color: white;
  }
  &:focus {
    background: @success-color;
    border-color: @success-color;
    color: white;
  }
}
.indicator-boxsand {
  display: grid;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
  margin-top: 20px;
  > .ant-btn[disabled] {
    background: #f9f9fb;
    border-color: #f9f9fb;
    color: @text-color-dark;
  }
}
.ant-progress-text {
  margin-left: 15px;
  font-size: 15px;
  color: @text-color-dark;
}
.ant-progress-inner {
  margin-left: 15px;
  width: 80%;
}
*.ant-progress-bg {
  background-color: @error-color !important;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: @success-color !important;
}
.assessmentList-boxmenu {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-top: 1px solid #f0eff4;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 15px 20px 15px 40px;
  font-size: 18px;
  color: @text-color-dark;
  font-weight: initial;
  opacity: 1;
  > .ant-btn {
    white-space: normal;
    > .ant-btn > span {
      display: inline-flex;
    }
  }
  &:focus {
    width: 100%;
    height: 100%;
    background: #f9f9fb;
    border-top: 1px solid #f0eff4;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 15px 20px 15px 40px;
    font-size: 18px;
    color: @primary-color;
    font-weight: initial;
    opacity: 1;
  }
}
.assessmentList-boxmenuActive {
  width: 100%;
  height: 100%;
  background: #f9f9fb;
  border-top: 1px solid #f0eff4;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 15px 20px 15px 40px;
  font-size: 18px;
  color: @primary-color;
  font-weight: initial;
  opacity: 1;
}
.Circles-indicator {
  margin-left: -25px;
  margin-bottom: -2px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-flex;
}
.Circles-done {
  color: @success-color;
  font-size: 16px;
  position: absolute;
  margin-top: -0.8rem;
}
.Circles-wait {
  border: 1px solid @primary-color !important;
  height: 15px;
  width: 15px;
}
.Circles-doing {
  background-color: @warning-color;
  height: 15px;
  width: 15px;
  border-style: double;
  border-color: #fff;
}
.over {
  overflow: hidden;
}
.indicatores-overflow {
  overflow-y: auto;
  max-height: 65vh;
}
.indicatores-overflow::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}
.indicatores-overflow::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}
.indicatores-overflow::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #8b929d38;
}
