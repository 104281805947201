.title-FormAssessment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
    margin-bottom: 0.5rem;

    h1 {
      margin-top: 5rem;
      margin-bottom: 0px;
      color: @primary-color;
      font-size: 2.5rem;
      font-weight: bold;
    }

    h2 {
      color: @gray-color;
    }
  }
}

.content-boxcontent {
  margin: 15px 40px 15px 40px;
  // display: flex;
  // align-items: center;
  border-radius: 10px;
  border: 1px solid #f0eff4;
  // background-color: #f9f9fb;
  // padding: 25px;
}

.content-textCriteria {
  padding: 10px 25px;
  // display: table-cell;
  // font-size: 20px;
  // vertical-align: middle;
  // text-align: left;
  align-items: center;
  display: flex;
  // justify-content: space-around;
  // letter-spacing: 0.25px;
  color: @primary-color;
}

.textCriteria {
  font-weight: bold;
  margin: 0;
  color: @primary-color;
}

.content-boxmaincontent {
  // border-bottom: 1px solid #f0eff4;
  padding: 0px 0px 20px 0px;
}

.view-content-boxmaincontent {
  border: 1px solid #f0eff4;
}

.content-boxsmalltopcontent {
  border-radius: 6px;
  border: 1px solid #f0eff4;
  background-color: @error-color;
  margin: 15px 10px -18px 60px;
}

.view-content-boxsmall {
  border-radius: 6px;
  border: 1px solid rgb(218, 218, 218);
  background-color: #fff;
  margin: 15px 10px -18px 60px;
}

.view-content-boxsmall.content-textcenter {
  color: #000000;
}

.content-textcenter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  letter-spacing: 0.25px;
  padding: 3px 15px;
}

.content-boxcontentstep.view {
  border: 1px solid rgb(218, 218, 218);
}

.content-boxcontentstep {
  border: 1px solid @error-color;
  margin: 0px 40px 15px 40px;
  padding: 35px 20px 10px 20px;
  border-radius: 10px;

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }
}

.content-boxcontentgray {
  border-radius: 10px;
  display: flex;
  border: 1px solid #f0eff4;
  background-color: #f9f9fb;
  margin: 10px 40px 0px 40px;
  align-items: center;
  padding: 15px 25px 15px 25px;
}

.content-textSteps {
  display: table-cell;
  font-size: 20px;
  vertical-align: middle;
  text-align: left;
  align-items: center;
  justify-content: space-around;
  letter-spacing: 0.25px;
  color: @primary-color;
}

.content-boxchooseyes {
  width: 165px;
  font-size: 20px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #f0eff4;
  background-color: #f9f9fb;
  color: @primary-color;
  margin: 10px 5px 15px 0px;
  padding: 12px 0px 12px 20px;

  .ant-radio {
    top: 0px;
  }
}

.content-boxchooseno {
  width: 165px;
  font-size: 20px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #f0eff4;
  background-color: #ffffff;
  color: @text-color-dark;
  margin: 15px 15px 10px 10px;
  padding: 12px 0px 12px 20px;

  .ant-radio {
    top: 0px;
  }
}

.content-upload-bordergray.ant-btn {
  background: #ffffff;
  border: 1px solid @text-color-dark;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 0 40px;
  height: 45px;
  color: @primary-color;

  &:hover {
    border: 1px solid @text-color-dark;
  }
}

.content-upload-text {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1px;
  color: @success-color;
  font-size: 17px;
}

.content-functionStep {
  display: flex;
  justify-content: space-between;
  padding: 25px 40px;

  .ant-form-item {
    margin-bottom: 0px;
  }
}

.content-Buttonnextstep {
  border: 1px solid;
  background: @primary-color;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  height: 50px;
  width: 150px;
  font-size: 15;
  letter-spacing: 0.1px;
  color: #ffffff;

  &:hover {
    border: 1px solid;
    background: @primary-color;
    border: 1px solid #f0eff4;
    color: #ffffff;
  }

  &:focus {
    border: 1px solid;
    background: @primary-color;
    border: 1px solid #f0eff4;
    color: #ffffff;
  }
}

.Button-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.content-ButtonSavestep {
  background: white;
  border: 1px solid @success-color;
  border-radius: 10px;
  height: 50px;
  width: 150px;
  font-size: 15;
  color: @success-color;
  letter-spacing: 0.1px;

  &:hover {
    background: white;
    border: 1px solid @success-color;
    color: @success-color;
  }

  &:focus {
    background: white;
    border: 1px solid @success-color;
    color: @success-color;
  }
}

.content-ButtonPreviousstep {
  border: 1px solid;
  background: #ffffff;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  height: 50px;
  width: 150px;
  font-size: 15;
  letter-spacing: 0.1px;
  color: @primary-color;

  &:hover {
    border: 1px solid;
    background: #ffffff;
    border: 1px solid #f0eff4;
    color: @primary-color;
  }

  &:focus {
    border: 1px solid;
    background: #ffffff;
    border: 1px solid #f0eff4;
    color: @primary-color;
  }
}

.content-help {
  margin-right: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    padding: 3.2px 0px;
  }
}

.BoxComment-boxcontentmoredetil {
  border: 1px solid #f0eff4;
  margin: 0px 40px 15px 40px;
  padding: 20px 0px 0px 0px;
  border-radius: 10px;
}

.BoxComment-boxchooseyes {
  width: 160px;
  height: 50px;
  font-size: 20px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #f0eff4;
  background-color: #f9f9fb;
  margin: 15px 5px 10px 15px;
  padding: 0px 0px 0px 10px;
}

.BoxComment-boxchooseno {
  width: 160px;
  height: 50px;
  font-size: 20px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #f0eff4;
  background-color: #ffffff;
  color: @text-color-dark;
  margin: 15px 15px 10px 10px;
  padding: 0px 0px 0px 10px;
}

.BoxComment-boxinput {
  margin: 5px 10px 15px 20px;
  border: 1px solid #f0eff4;
  border-radius: 10px;
  align-items: center;
}

.BoxComment-maintexinrightbox {
  text-align: left;
  font-weight: bold;
  margin: 0px 0px 10px 20px;
  color: @primary-color;
  letter-spacing: 0.13px;
}

.text-Answer {
  font-size: 18px;
  color: @primary-color;
  font-weight: bold;
}

.content-ButtonSaveandNext {
  display: flex;
  justify-content: space-between;
}

.content-iconstep {
  font-size: 20px;
}

///////////////////
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.boxleft-container {
  overflow-y: auto;
  max-height: 87vh;
}