
.user-form{
  padding: 0 18px;  
  align-items: flex-start;
  justify-content: flex-start;
  width: 100% !important;
  border-radius: 5px;
  max-width: 100%;
}
.user-fromBox *.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 3rem;
  justify-content: center !important;
  align-items: center !important;
  font-size: 17px;
 }

.user-formSearch{
  padding:  0 0px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 5px;
}

.user-search{
  align-items: flex-start;
  justify-content: flex-start;
  // display: flex;
 
}

.user-button{
  height: '50px' !important;
  border-radius: 1px;
  padding: 0px 10px 0px ;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
}

 .user-form-font {
  color: #001A70 !important;
  font-size: 18px;
  padding: 0px 20px ;
 }
 
 .user-btn-reset {
  color: #2C2F8F !important;
  border-color: #2C2F8F !important;
  background: #fff !important;
  cursor: pointer;
  border-Radius: 4px;
  height: 3.2rem;
  width: 100px !important;
 }

 .user-btn-search{  
  color: #ffffff!important;
  border: none !important;
  background: #2C2F8F !important;
  cursor: pointer;
  border-Radius: 4px;
  height: 3.2rem;
  width: 100px !important;
  

 }

 .user-input-font {
  color: @primary-color;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  height: 3rem;

 }

.form-button-arrow *.ant-select-arrow { 
  color: #63799B;
  // align-items: flex-end;
  // justify-self: flex-end;
}

// .user-button-add{
//   border: none;
//   color: #fff !important;
//   border-radius: 5px;
//   background-color:@error-color;
//   width: 100px ;
//   height: 40px;
  
//   &:hover{
//     color: #fff ;
//     background-color:@error-color ;
    
//   }
//   &:focus{
//     color: #fff ;
//     background-color:@error-color;
    
//   }
// }
 

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: @layout-header-background;
 }
 
 .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: @primary-color;
    color: @error-color !important;
    background-color: white;
 }

 .ant-select {
  color: #63799B; 
}
