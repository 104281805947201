// ----- screen xs ---------//

@media only screen and (max-width: 576px) {
  .text-title {
    font-size: 2rem;
  }
  .password *.ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    padding: 0 0px;
  }
  
 .form-item-password{
  color: #63799B !important;
  font-size: 18px;
  padding: 0px 0px;
  }

  .button{
    display:flex;
    flex-direction: row;
    font-size: 18px;
    justify-content:flex-start;
  }
  
  .FromUser-title-text{
    font-size: 2rem;
    font-weight: bold;
    color: #2C2F8F;
    padding: 0 0px;
  }

  .user-form{
    max-width: 100%;
    padding: 0 10px; 
    border-radius: 5px; 
    width: 100% !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .user-fromBox *.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    
    width: auto;
    height: 3rem;
    font-size: 17px;
    justify-content: center !important;
    align-items: center !important;
   
   }
  .user-input-font {
    height:1rem;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    color: @primary-color;
  
   }
   .form-button-cancle {
    cursor: pointer;
    color: #2a2866;
    border-radius: 5px;
    width: 110px !important;
    background-color: #f0eff4 !important;
  }

  .form-button-save { 
    border: none;  
    width: 110px;
    cursor: pointer;
    color: #ffffff;
    border-radius: 5px;
    background-color: #1fa361;
    
  }

  .boxright-ListAssessment {
    padding: 1rem 1rem 3rem 1rem;
  }
  .assessmentList-boxsuccess {
    display: flex;
    flex-wrap: wrap; 
    padding: 5px 10px 5px 10px;
    justify-content: space-evenly;
  }
  
  .document-distance{
    margin: 0px -30px ;
  }

  .user-table {
    max-width: 100%;
    min-height: 100%;
    padding: 0px 0px;
    
  }
  .title {
    font-size: 2rem;
    font-weight: bold;
    padding: 0px 20px 0px 0px;
    color: #2c2f8f !important;
  }

}

