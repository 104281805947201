@import "~antd/lib/style/themes/default.less";
.sider-layout {
  flex: 0 0 90px !important;
  // width: 90px !important;
  // max-width: 90px !important;
  // min-width: 90px !important;
  background-color: transparent !important;
}
.sider-container {
  height: 100vh;
  max-height: 90vh;
}
.sider-content {
  color: @text-color-dark;
  height: 100%;
  background-color: #fff;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom-right-radius: 20px;
}
.contact {
  text-align: center;
  font-size: 9px;
}
.contact div {
  font-size: 12px;
  font-weight: bold;
  color: @success-color;
}

.sider-layout-header {
  background-color: @layout-header-background;
}

.sider-header {
  height: @layout-header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top-right-radius: 20px;
}
.sider-menu-item {
  display: flex;
  padding: 5px;
  margin-bottom: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: @text-color-dark;
  width: 70px;
  height: 68px;
  font-size: 10px;
  border-radius: 10px;
  overflow-wrap: break-word;
  // background-color: @layout-header-background;
  &:hover {
    color: #fff;
    font-weight: bold;
    width: 70px;
    background-color: @layout-header-background;
    .sider-icon {
      filter: brightness(0) invert(1);
    }
  }
}

.active-sider {
  color: #fff;
  width: 70px;
  font-weight: bold;
  background-color: @layout-header-background;
  .sider-icon {
    filter: brightness(0) invert(1);
  }
}

.sider-menu-item-text {
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
}

.sider-item-center {
  display: inline;
  justify-content: center;
  align-items: center;
}

.sider-menu-item-icons {
  fill: @text-color-dark;
  width: 25px;
}

.sider-font {
  font-family: @font-family;
  font-size: 50px;
}
